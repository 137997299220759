import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import ResourceCard from "../components/ResourceCard"
import getRelatedPosts from "../utils/getRelatedPosts"
import RenderContent from "../components/RenderContent"
import { Grid, Box } from "../elements"

// Import styles
import "../styles/blog-post.scss"
import "../styles/resource-post.scss"

// Images
import ArrowBlue from "../images/inline-svgs/right-arrow-blue.svg"

const ResourcePost = ({ data, location }) => {
  const post = data.ghostPost
  const all_posts = data.all_posts.edges
  const relatedPosts = getRelatedPosts(post, all_posts)
  const htmlAst = post.childHtmlRehype && post.childHtmlRehype.htmlAst
  const transformedHtml = post.childHtmlRehype && post.childHtmlRehype.html

  return (
    <Layout>
      <MetaData location={location} data={data} type="article" />

      <div className="post-view post-view--resources">
        <Box
          as="article"
          className={
            "wrapper-800" +
            (post.feature_image == null ? " no-feature-image" : "")
          }
          pb="60px"
        >
          <header className="post__header">
            <div className="post__primary-tag">
              <Link to="/resources/" className="go-back-link">
                <span className="icon">
                  <ArrowBlue />
                </span>
                <span className="text">Resources</span>
              </Link>
            </div>

            <h1 className="post__title">{post.title}</h1>
          </header>

          <div className="post__body">
            <div
              className="load-external-scripts"
              dangerouslySetInnerHTML={{ __html: post.codeinjection_head }}
            ></div>
            <RenderContent
              htmlAst={htmlAst}
              html={transformedHtml || post.html}
            />
          </div>
        </Box>

        {/* Related Posts */}
        {relatedPosts.length > 0 && (
          <div className="related-posts related-posts--resources">
            <div className="wrapper-1200">
              <div className="related-posts__header">Related Resources</div>
              <Grid
                gridTemplateColumns={["1fr", null, "repeat(3, 1fr)"]}
                gridGap="40px 24px"
                justifyItems="center"
              >
                {relatedPosts.map(relatedPost => (
                  <ResourceCard
                    data={relatedPost.node}
                    key={relatedPost.node.id}
                  />
                ))}
              </Grid>
            </div>
          </div>
        )}

        {/* Add codeinjection scripts */}
        <div
          className="load-external-scripts"
          dangerouslySetInnerHTML={{ __html: post.codeinjection_foot }}
        ></div>
      </div>
    </Layout>
  )
}

export default ResourcePost

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      childHtmlRehype {
        html
        htmlAst
      }
      ...GhostPostFields
    }

    all_posts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "resources" } } }
      sort: { order: ASC, fields: published_at }
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`
