import React, { useMemo } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"

import getRedirectUrl from "../../utils/getRedirectUrl"
import { Box, Flex, Heading, StyledLink } from "../../elements"

type Props = {
  data: {
    title: string
    feature_image: string
    slug: string
    tags: Array<Tag>
    primary_tag: {
      slug: string
      name: string
    }
    codeinjection_head: string
  }
  styles: {
    linkColor: string
    eyebrowColor: string
  }
}

type Tag = {
  slug: string
  name: string
}

const ResourceCard = ({ data, styles }: Props) => {
  const eyebrowText = data?.tags?.[1]?.name
  let eyebrowColor = "primary.base"
  let linkText = "Read more"
  const isPrivate = useMemo(
    () => data?.tags.find(o => o.slug === "hash-private"),
    [data?.tags]
  )
  const featuredImage = getImage(data?.featureImageSharp)
  const externalLink = useMemo(() => getRedirectUrl(data?.codeinjection_head), [
    data?.codeinjection_head,
  ])

  if (isPrivate) {
    linkText = "Request " + data?.tags?.[1]?.name
  } else {
    switch (data.tags[1].slug) {
      case "white-paper":
        linkText = "Read white paper"
        eyebrowColor = "#db7d11"
        break
      case "datasheet":
        linkText = "Read datasheet"
        eyebrowColor = "#00a697"
        break
      case "case-study":
        linkText = "Read case study"
        eyebrowColor = "#67519e"
        break
      case "webinar":
        linkText = "Watch now"
        eyebrowColor = "#9d0fb0"
        break
      case "video":
        linkText = "Watch now"
        eyebrowColor = "#24a1c1"
        break
      case "online-meetup":
        linkText = "Watch now"
        break
      default:
        linkText = "Read more"
    }
  }

  return (
    <Box as="article" maxWidth={392} position="relative">
      <Flex flexDirection="column" justifyContent="flex-start">
        {featuredImage && (
          <GatsbyImage
            image={featuredImage}
            alt={data.title}
            imgStyle={{
              borderRadius: "8px",
              height: "230px",
              objectFit: "cover",
              verticalAlign: "middle",
            }}
            style={{
              boxShadow: "0 8px 30px 0 rgba(0, 0, 0, 0.14)",
              borderRadius: "8px",
              height: "230px",
            }}
          />
        )}
        <Flex flexDirection="column" pt={["20px"]} flex={1}>
          {eyebrowText && (
            <Heading
              as="div"
              variant="uppercase"
              fontSize={["1.2rem", null, null, "1.2rem"]}
              lineHeight="1.66"
              mb="4px"
              color={styles?.eyebrowColor || eyebrowColor}
            >
              {eyebrowText}
            </Heading>
          )}
          <Heading
            as="h2"
            fontSize={["2rem"]}
            fontWeight="medium"
            lineHeight="1.4em"
          >
            {data.title}
          </Heading>
          {!!externalLink ? (
            <>
              <StyledLink
                icon="arrow"
                mt="16px"
                isExternal
                href={externalLink}
                zIndex={1}
                {...{ color: styles?.linkColor }}
              >
                {linkText}
              </StyledLink>
              <InvisibleLinkExternal href={externalLink} />
            </>
          ) : (
            <>
              <StyledLink
                icon="arrow"
                mt="16px"
                to={`/${data.primary_tag.slug}/${data.slug}/`}
                {...{ color: styles?.linkColor }}
              >
                {linkText}
              </StyledLink>
              <InvisibleLinkInternal
                to={`/${data.primary_tag.slug}/${data.slug}/`}
              />
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

const InvisibleLinkStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const InvisibleLinkExternal = styled.a`
  ${InvisibleLinkStyles}
`

const InvisibleLinkInternal = styled(Link)`
  ${InvisibleLinkStyles}
`

export default ResourceCard
